import { ScrollTrigger } from 'gsap/ScrollTrigger';
import getHiddenElementHeight from '../utils/getHiddenElementHeight.utlil';
import gsap from 'gsap';

export default class DrawTimeLine {
   private section: HTMLElement;
   private timeLine: HTMLElement | null;
   private dots: HTMLElement[];

   constructor(section: HTMLElement) {
      this.section = section;
      this.timeLine = this.section.querySelector('.bar');

      this.dots = [...this.section.querySelectorAll('.dot')] as HTMLElement[];

      gsap.registerPlugin(ScrollTrigger);

      this.handleDrawTimeLine();
      this.handleScaleDots();
   }

   handleDrawTimeLine = async (): Promise<void> => {
      if (!this.timeLine) return;

      this.setInitialValue();
      this.drawLine();
   };

   setInitialValue = async (): Promise<void> => {
      gsap.set(this.timeLine, {
         scaleY: 0,
         transformOrigin: '0 0',
      });
   };

   drawLine = async (): Promise<void> => {
      const animation: GSAPAnimation = gsap.to(this.timeLine, { scaleY: 1, duration: 1 });

      ScrollTrigger.create({
         trigger: this.section,
         start: 'top top',
         end: 'bottom bottom',
         scrub: true,
         animation: animation,
      });
   };

   handleScaleDots = async (): Promise<void> => {
      this.dots.forEach((dot) => this.scaleDot(dot));
   };

   scaleDot = (dot: HTMLElement) => {
      const animation = gsap.fromTo(dot, { scale: 0 }, { scale: 1, duration: 0.2 });

      ScrollTrigger.create({
         trigger: dot,
         start: 'top 35%',
         animation: animation,
         scrub: 0.5,
      });
   };
}
