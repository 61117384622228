import setCookie from '../utils/setCookie.util';
import cookieGet from '../utils/getCookie.util';

class CookiesPopup {
   constructor() {
      this.init();
   }

   init() {
      const cookiesPopup = document.querySelector('.cookies-popup');
      const cookiesPopupAccept = document.querySelector('.cookies-popup__accept');

      if (cookiesPopup && cookiesPopupAccept) {
         if (!cookieGet('cookies-accepted')) {
            setTimeout(() => {
               cookiesPopup.classList.add('cookies-popup-visible');
            }, 300);
            cookiesPopupAccept.addEventListener('click', this.handleAcceptCookie);
         } else {
            cookiesPopup.remove();
         }
      }
   }

   handleAcceptCookie = () => {
      const popUp = document.querySelector('.cookies-popup');
      popUp?.classList.remove('cookies-popup-visible');
      setTimeout(() => {
         popUp?.remove();
         setCookie('cookies-accepted', 'true', '/', 9999);
      }, 500);
   };
}

export default CookiesPopup;
