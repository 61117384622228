import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default class MobileContactBar {
   private bar: HTMLElement;

   constructor() {
      this.bar = document.querySelector('.contact-bar') as HTMLElement;

      gsap.registerPlugin(ScrollTrigger);

      this.init();
   }

   init = async (): Promise<void> => {
      ScrollTrigger.create({
         start: 'top -70',
         end: '90% 50%',
         toggleClass: { className: 'contact-bar--visible', targets: this.bar },
      });
   };
}
