import disableScroll from '../utils/disableScroll.util';
import enableScroll from '../utils/enableScroll.util';

export default class Modal {
   private modalElement: HTMLElement;
   private modalTogglers: HTMLElement[];
   private modalClose: HTMLElement;
   private isCreditModal: boolean;
   private isOpen: boolean;

   constructor(modalElement: HTMLElement, modalTogglers: NodeListOf<HTMLElement>, isCreditModal: boolean = false) {
      this.modalElement = modalElement;
      this.modalTogglers = [...modalTogglers];
      this.modalClose = this.modalElement.querySelector('.modal__close') as HTMLElement;
      this.isCreditModal = isCreditModal;
      this.isOpen = false;

      this.initListeners();
   }

   initListeners = () => {
      this.modalTogglers.forEach((toggler) => toggler.addEventListener('click', this.handleToggleModal));
      this.modalClose.addEventListener('click', this.handleToggleModal);

      if (this.isCreditModal) {
         document.addEventListener('wpcf7mailsent', (e) => {
            window.dataLayer.push({
               event: 'generate_lead',
               leadType: 'credit',
               mail: (document.querySelector('[name="your-email"]') as HTMLInputElement).value,
               phone: (document.querySelector('[name="your-phone"]') as HTMLInputElement).value,
               creditValue: (document.querySelector('#credit_range') as HTMLInputElement).value,
               currency: 'PLN',
               paymentTime: (document.querySelector('#time_range') as HTMLInputElement).value,
               instalmentType: (document.querySelector('[name="rate_type"]:checked') as HTMLInputElement).dataset.formvalue,
            });
         });
      }
   };

   handleToggleModal = async (): Promise<void> => {
      this.isCreditModal ? this.setValuesOfForm() : null;

      this.isOpen = !this.isOpen;

      this.modalElement.classList.toggle('modal--visible');

      this.isOpen ? disableScroll() : enableScroll();

      this.isOpen
         ? setTimeout(() => {
              this.modalElement.addEventListener('click', this.handleClickOnBackground);
           }, 100)
         : this.modalElement.removeEventListener('click', this.handleClickOnBackground);

      setTimeout(() => {
         this.isOpen
            ? this.modalElement.addEventListener('click', this.handleClickOnBackground)
            : this.modalElement.removeEventListener('click', this.handleClickOnBackground);
      }, 100);

      this.isOpen ? document.addEventListener('keydown', this.handlePressKey) : document.removeEventListener('keydown', this.handlePressKey);
   };

   private handleClickOnBackground = (event: MouseEvent) => {
      const contentWrapper = document.querySelector<HTMLElement>('.modal__content-wrapper');
      const clickedTarget = event.target as HTMLElement;

      if (!contentWrapper) return;
      if (!contentWrapper.contains(clickedTarget) || clickedTarget.classList.contains('modal-close-button-element')) {
         this.handleToggleModal();
      }
   };

   private handlePressKey = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') this.handleToggleModal();
   };

   public setValuesOfForm = async (): Promise<void> => {
      const sum = document.querySelector('[name="credit-sum"]') as HTMLInputElement;
      const time = document.querySelector('[name="credit-time"]') as HTMLInputElement;
      const installment = document.querySelector('[name="installment-type"]') as HTMLInputElement | any;

      const calculatorSumValue = (document.querySelector('#credit_range') as HTMLInputElement).value;
      const calculatorTimeValue = (document.querySelector('#time_range') as HTMLInputElement).value;
      const calculatorInstallmentType = (document.querySelector('[name="rate_type"]:checked') as HTMLInputElement).dataset.formvalue;

      sum.value = `${calculatorSumValue} zł`;
      time.value = `${calculatorTimeValue} ${this.getCorrectMonthVariety(calculatorTimeValue)}`;
      installment.value = calculatorInstallmentType;
   };

   private getCorrectMonthVariety = (monthCount: string): string => {
      const parsedMonthCount = parseInt(monthCount);

      if (parsedMonthCount === 1) {
         return 'Miesiąc';
      } else if (parsedMonthCount > 1 && parsedMonthCount < 5) {
         return 'Miesiące';
      } else {
         return 'Miesięcy';
      }
   };
}
