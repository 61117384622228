import isIntersecting from '../../utils/isIntersecting.util';
interface localizationInterface {
   zoom: number;
   lat: number;
   lng: number;
   marker: string;
   index: number;
}
export default class Map {
   private center: localizationInterface;
   private localizations: localizationInterface[];
   private styles: object[];
   private section: HTMLElement;
   private map: any | null;
   private mapElement: HTMLElement;

   constructor(mapData: any, section: HTMLElement) {
      this.center = mapData.center;
      this.localizations = mapData.localizations;
      this.styles = mapData.styles;
      this.section = section;
      this.map = null;
      this.mapElement = this.section.querySelector('.map__google-map') as HTMLElement;
   }

   init = async (): Promise<void> => {
      isIntersecting(this.section, this.initMap);
   };

   initMap = async (): Promise<void> => {
      this.map = new (<any>window).google.maps.Map(this.mapElement, {
         zoom: this.center.zoom,
         center: {
            lat: this.center.lat,
            lng: this.center.lng,
         },
         styles: this.styles,
         zoomControl: true,
         mapTypeControl: false,
         streetViewControl: false,
         rotateControl: false,
         rotateControlOptions: false,
         scaleControl: false,
         fullscreenControl: false,
      });

      this.initMarkers();
   };

   initMarkers = async (): Promise<void> => {
      this.localizations.forEach((localization) => {
         new google.maps.Marker({
            position: { lat: localization.lat, lng: localization.lng },
            icon: localization.marker,
            map: this.map,
         });
      });
   };

   flyTo = async ({ currentTarget }: Event): Promise<void> => {
      const index = parseInt((currentTarget as HTMLElement).dataset.index as string);
      const center = this.localizations.find((item) => item.index === index) as localizationInterface;

      if (!center.lat || !center.lng) return;

      this.map.setCenter({ lat: center.lat, lng: center.lng });
   };
}
