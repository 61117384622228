import { threeColsSlider } from './sliders';

export default class ThreeCols {
   private section: HTMLElement;
   private slider: HTMLElement | null;
   constructor(section: HTMLElement) {
      this.section = section;
      this.slider = this.section.querySelector('.three_cols__slider');

      this.handleInitSlider();
   }

   handleInitSlider = async (): Promise<void> => {
      if (!this.slider) return;
      threeColsSlider(this.slider);
   };
}
