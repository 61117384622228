import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbars } from 'overlayscrollbars';

export default class ScrollBars {
   private container: HTMLElement;
   private osInstance: any;

   constructor(container: HTMLElement) {
      this.container = container;

      this.init();
   }

   init = async (): Promise<void> => {
      this.osInstance = OverlayScrollbars(this.container, {
         overflow: {
            x: 'hidden',
         },
      });
   };
}
