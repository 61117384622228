import { bigFiveSlider } from './sliders';

export default class BigFive {
   private section: HTMLElement;
   private slider: HTMLElement;

   constructor(section: HTMLElement) {
      this.section = section;
      this.slider = this.section.querySelector('.big-five__slider') as HTMLElement;

      bigFiveSlider(this.slider);
   }
}
