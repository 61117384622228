interface breakPoint {
   max: number;
   min: number;
}

const isBetweenBreakPoints = (breakPoints: breakPoint[]): Boolean => {
   const currentWidth = window.innerWidth;
   let isBetween = false;

   breakPoints.forEach((breakpoint) => {
      if (currentWidth > breakpoint.min && currentWidth < breakpoint.max) isBetween = true;
   });

   return isBetween;
};

export default isBetweenBreakPoints;
