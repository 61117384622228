import getHiddenElementHeight from '../utils/getHiddenElementHeight.utlil';
import { gsap } from 'gsap';

export default class FooterDropDown {
   private parent: HTMLElement;
   private list: HTMLElement;
   private toggler: HTMLElement;
   private isOpen: Boolean;
   private tl: GSAPTimeline | null;

   constructor(parent) {
      this.parent = parent;
      this.list = parent.querySelector('.my-child-menu ');
      this.toggler = parent.querySelector('.my-menu-item-parent');
      this.isOpen = false;
      this.tl = null;

      this.initListeners();
      this.initTimeLines();
   }

   private initListeners = async (): Promise<void> => {
      this.toggler.addEventListener('click', this.handleToggleMenu);
   };

   private initTimeLines = async (): Promise<void> => {
      this.tl = gsap.timeline();
      this.tl.to(this.list, { height: getHiddenElementHeight(this.list), duration: 0.3 }).pause();
   };

   private handleToggleMenu = async (): Promise<void> => {
      if (!this.tl) return;

      this.isOpen = !this.isOpen;
      this.toggler.classList.toggle('my-menu-item-parent--open');
      this.isOpen ? this.tl.play() : this.tl.reverse();
   };
}
