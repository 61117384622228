import List from './map/List';
import Map from './map/Map';
import ScrollBars from './map/Scroll';

export default class MapController {
   private section: HTMLElement;
   private input: HTMLElement;
   private localizationsNames: HTMLElement[];
   private map: any;
   public listElement: HTMLElement;
   public List: any;
   public listContainer: HTMLElement;

   constructor(section: HTMLElement) {
      this.section = section as HTMLElement;
      this.listElement = this.section.querySelector('.map__list') as HTMLElement;
      this.input = this.section.querySelector('input') as HTMLElement;
      this.localizationsNames = [...this.listElement.querySelectorAll('.map__list-item-name')] as HTMLElement[];
      this.listContainer = this.section.querySelector('.map__list-container') as HTMLElement;

      this.List = new List(this.listElement as HTMLElement);

      this.initListeners();
      this.initMap();
      this.initScrollBars();
   }

   initListeners = async (): Promise<void> => {
      this.input.addEventListener('keyup', (event) => {
         this.List.filterList(event as Event);
      });

      this.localizationsNames.forEach((item) => {
         item.addEventListener('click', (event) => {
            this.map.flyTo(event as Event);
         });
      });
   };

   initMap = async (): Promise<void> => {
      this.map = new Map(map_data, this.section);
      this.map.init();
   };

   initScrollBars = async (): Promise<void> => {
      new ScrollBars(this.listContainer);
   };
}
