export default class SubMenu {
   private parent: HTMLElement;
   private subMenu: HTMLElement;
   private nav: HTMLElement;
   private hideButton: HTMLElement;
   constructor(parent: HTMLElement, nav: HTMLElement) {
      this.parent = parent;
      this.nav = nav;
      this.subMenu = this.nav.querySelector(`[data-slug="${this.parent.dataset.children}"]`) as HTMLElement;
      this.hideButton = this.subMenu.querySelector('.my-child-item--back') as HTMLElement;

      this.initListener();
   }

   toggleSubMenu = async (e): Promise<void> => {
      this.subMenu.classList.toggle('my-child-menu--open');
   };

   initListener = async (): Promise<void> => {
      this.parent.addEventListener('click', this.toggleSubMenu);
      this.hideButton.addEventListener('click', this.toggleSubMenu);
   };
}
