export default function isIntersecting(element: HTMLElement, cb: VoidFunction, rootMargin = "200px"): void {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        cb();
        observer.unobserve(element);
      }
    }),
      { root: null, rootMargin };
  });
  observer.observe(element);
}
