import { boxesSlider } from './sliders';

export default class Boxes {
   private section: HTMLElement;
   public slider: HTMLElement;

   constructor(section: HTMLElement) {
      this.section = section;
      this.slider = this.section.querySelector('.boxes__slider') as HTMLElement;

      boxesSlider(this.slider);
   }
}
