import disableScroll from '../utils/disableScroll.util';
import enableScroll from '../utils/enableScroll.util';
import getHiddenElementHeight from '../utils/getHiddenElementHeight.utlil';
import { gsap } from 'gsap';
import SubMenu from './SubMenu';
export default class Header {
   private closeMenu: HTMLElement | null;
   private openMenu: HTMLElement | null;
   private primaryNav: HTMLElement | null;
   private primaryNavTogglers: HTMLElement[];
   private isPrimaryNavOpen: Boolean;
   public nav: HTMLElement;
   private isOpen: Boolean;
   public subMenuParents: HTMLElement[];
   private dropDownTl: GSAPTimeline | null;

   constructor() {
      this.closeMenu = document.querySelector('.close-menu svg');
      this.openMenu = document.querySelector('.hamburger');
      this.primaryNav = document.querySelector('.top-bar__nav');
      this.primaryNavTogglers = [
         document.querySelector('.top-bar__nav-toggler') as HTMLElement,
         document.querySelector('.close-main-menu') as HTMLElement,
      ];
      this.isPrimaryNavOpen = false;
      this.nav = document.querySelector('.header__secondary-nav') as HTMLElement;
      this.isOpen = false;
      this.subMenuParents = [...this.nav.querySelectorAll('.cat-nav__list-item--parent')] as HTMLElement[];
      this.dropDownTl = null;

      this.initListeners();
      this.initTimeLines();
      this.initSubMenus();
   }

   private initListeners = async (): Promise<void> => {
      this.closeMenu?.addEventListener('click', this.handleToggleMenu);
      this.openMenu?.addEventListener('click', this.handleToggleMenu);
      this.primaryNavTogglers.forEach((item) => item.addEventListener('click', this.handleTogglePrimaryNav));
   };

   private initTimeLines = async (): Promise<void> => {
      this.dropDownTl = gsap.timeline();
      this.dropDownTl.to(this.primaryNav, { height: getHiddenElementHeight(this.primaryNav), duration: 0.3 }).pause();
   };

   private handleToggleMenu = async (): Promise<void> => {
      this.nav.classList.toggle('header__secondary-nav--open');
      this.isOpen = !this.isOpen;

      this.isOpen ? disableScroll() : enableScroll();
      this.isOpen ? document.addEventListener('keydown', this.handlePressKey) : document.removeEventListener('keydown', this.handlePressKey);
   };

   private handlePressKey = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') this.handleToggleMenu();
   };

   private initSubMenus = async (): Promise<void> => {
      this.subMenuParents.forEach((parent) => {
         new SubMenu(parent as HTMLElement, this.nav);
      });
   };

   private handleTogglePrimaryNav = async (): Promise<void> => {
      if (!this.dropDownTl) return;

      this.primaryNavTogglers.forEach((toggler) => toggler.classList.toggle('nav-is-open'));
      this.isPrimaryNavOpen = !this.isPrimaryNavOpen;
      this.isPrimaryNavOpen ? this.dropDownTl.play() : this.dropDownTl.reverse();
   };
}
