import 'overlayscrollbars/overlayscrollbars.css';

import LazyLoad from 'vanilla-lazyload';
import { OverlayScrollbars } from 'overlayscrollbars';
import disableScroll from '../utils/disableScroll.util';
import enableScroll from '../utils/enableScroll.util';
import isIntersecting from '../utils/isIntersecting.util';

export default class Search {
   private searchTogglers: NodeListOf<HTMLElement>;
   private searchWrapper: HTMLElement;
   private input: HTMLInputElement;
   private searchButton: HTMLElement;
   private listWrapper: HTMLElement;
   private searchPhraseElement: HTMLElement;
   private prevFilters: [];
   private filtersWrapper: HTMLElement;
   private isOpen: boolean;
   private loader: HTMLElement;
   private scroll: any;
   private currentPage: string;
   private list: string;

   constructor() {
      this.searchTogglers = document.querySelectorAll('.search-toggler') as NodeListOf<HTMLElement>;
      this.searchWrapper = document.querySelector('.search-wrapper') as HTMLElement;
      this.input = document.querySelector('#header-search-input') as HTMLInputElement;
      this.searchButton = document.querySelector('.search-wrapper__input-button') as HTMLElement;
      this.listWrapper = document.querySelector('.search-wrapper__results-list') as HTMLElement;
      this.searchPhraseElement = document.querySelector('#search-text') as HTMLElement;
      this.prevFilters = [];
      this.filtersWrapper = document.querySelector('.search-wrapper__results-filters') as HTMLElement;
      this.isOpen = false;
      this.loader = document.querySelector('.search__loader') as HTMLElement;
      this.scroll = null;
      this.currentPage = '1';
      this.list = '';

      this.initListeners();
   }

   initListeners = (): void => {
      this.searchTogglers.forEach((item) => item.addEventListener('click', this.toggleSearchContainer));
      this.searchButton.addEventListener('click', this.handleSearch);
   };

   toggleSearchContainer = (): void => {
      this.isOpen = !this.isOpen;
      this.searchWrapper.classList.toggle('search-wrapper--visible');

      if (this.isOpen) {
         disableScroll();
         document.addEventListener('keydown', this.handlePressKey);
      } else {
         enableScroll();
         document.removeEventListener('keydown', this.handlePressKey);
      }
   };

   handlePressKey = (event: KeyboardEvent): void => {
      if (event.key === 'Enter') this.handleSearch();
      if (event.key === 'Escape') this.toggleSearchContainer();
   };

   handleSearch = async (): Promise<void> => {
      await this.toggleLoader();
      const formData = await this.getFormData();
      const resp = await fetch(window.global_vars.ajax, { method: 'POST', body: formData });
      const response = await resp.json();

      this.listWrapper.innerHTML = response.searchList.list;
      this.list = response.searchList.list;

      this.createFilters(response.searchList.postTypes, true);

      this.searchWrapper.classList.add('search-wrapper--with-results');

      this.setSearchPhrase();

      await this.setOSInstance();
      new LazyLoad();
      await this.toggleLoader();
   };

   setOSInstance = async (): Promise<void> => {
      if (this.scroll) {
         this.scroll.destroy();
      }
      this.scroll = OverlayScrollbars(this.listWrapper, {
         overflow: {
            x: 'hidden',
         },
      });
   };

   handlePerPage = async (): Promise<void> => {
      const lastChild = document.querySelector('.search-wrapper__results-list-item:last-child') as HTMLElement;

      if (!lastChild) return;

      isIntersecting(lastChild, this.setNewPerPage, '0px');
   };

   setNewPerPage = () => {
      this.currentPage = `${parseInt(this.currentPage) + 1}`;
      // this.handleSearch(false);
   };

   toggleLoader = (): Promise<void> => {
      return new Promise((resolve) => {
         this.loader.classList.toggle('search__loader--visible');
         resolve();
      });
   };

   getFormData = async (): Promise<FormData> => {
      return new Promise((resolve) => {
         const formData = new FormData();
         formData.append('action', 'get_posts');
         formData.append('page', this.currentPage);
         formData.append('search', this.input.value);

         resolve(formData);
      });
   };

   setSearchPhrase = () => {
      this.searchPhraseElement.textContent = this.input.value.trim();
   };

   createFilters = (filters, first) => {
      this.filtersWrapper.innerHTML = '';
      if (!filters) return;
      const temp = first ? {} : this.prevFilters;
      const filtersArr = this.prevFilters ? { ...temp, ...filters } : filters;
      this.prevFilters = filtersArr;
      let lastPriority = 0;
      for (var key in filtersArr) {
         const button = document.createElement('button');
         const span = document.createElement('span');
         button.innerHTML = filtersArr[key].name;
         button.dataset.posttype = filtersArr[key].type;
         button.classList.add('search-wrapper__results-filters-button', 'filtered');
         if (lastPriority < filtersArr[key].priority) {
            lastPriority = filtersArr[key].priority;
            const last = document.querySelector('.last-filter');
            if (last) last.classList.remove('last-filter');
            button.classList.add('last-filter');
         }
         button.style.order = filtersArr[key].priority;
         button.appendChild(span);
         button.addEventListener('click', (e) => this.listFilterHandler(e.currentTarget));
         this.filtersWrapper.append(button);
      }
   };

   listFilterHandler = (button) => {
      if (this.filtersWrapper.querySelectorAll('.filtered').length > 1 || !button.classList.contains('filtered')) {
         button.classList.toggle('filtered');
         const posts = this.listWrapper.querySelectorAll(`[data-type="${button.dataset.posttype}"]`);
         posts.forEach((post) => {
            button.classList.contains('filtered') ? post.classList.remove('hidden') : post.classList.add('hidden');
         });
      }
   };
}
