import BigFive from './components/BigFive';
import Boxes from './components/Boxes';
import DrawTimeLine from './components/DrawTimeLine';
import FooterDropDown from './components/FooterDropDown';
import Header from './components/Header';
import LazyLoadImages from './libs/LazyLoad.lib';
import MapController from './components/MapController';
import MobileContactBar from './components/MobileContactBar';
import Modal from './components/Modal';
import Search from './components/Search';
import Tabs from './components/Tabs';
import ThreeCols from './components/ThreeCols';
import { relatedPostsSlider } from './components/sliders';
import CookiesPopup from './components/Cookies';

document.addEventListener('DOMContentLoaded', () => {
   new LazyLoadImages();
   new Header();
   new MobileContactBar();
   new Search();
   new CookiesPopup();

   hanldeInitTabs();
   handleInitMapController();
   handleDrawTimeLine();
   handleInitBigFiveSlider();
   handleInitBoxes();
   handleFooterDropDowns();
   handleInitThreeCols();
   handleRelatedPostsSlider();
   handleContactModal();
});

const hanldeInitTabs = async (): Promise<void> => {
   const tabsSections = document.querySelectorAll<HTMLElement>('.tabs');

   tabsSections.forEach((section) => new Tabs(section));
};

const handleInitMapController = async (): Promise<void> => {
   const mapSections = document.querySelectorAll<HTMLElement>('.map');

   mapSections.forEach((section) => new MapController(section));
};

const handleDrawTimeLine = async (): Promise<void> => {
   const historySections = document.querySelectorAll<HTMLElement>('.history');

   historySections.forEach((section) => new DrawTimeLine(section));
};

const handleInitBigFiveSlider = async (): Promise<void> => {
   const bigFiveSections = document.querySelectorAll<HTMLElement>('.big-five');

   bigFiveSections.forEach((section) => new BigFive(section));
};

const handleInitBoxes = async (): Promise<void> => {
   const boxesSections = document.querySelectorAll<HTMLElement>('.boxes');

   boxesSections.forEach((section) => new Boxes(section));
};

const handleFooterDropDowns = async (): Promise<void> => {
   const footer = document.querySelector('.footer') as HTMLElement;

   const listsParents = footer.querySelectorAll<HTMLElement>('.nav__list-item');

   listsParents.forEach((parent) => new FooterDropDown(parent));
};

const handleInitThreeCols = async (): Promise<void> => {
   const threeColsSection = document.querySelectorAll<HTMLElement>('.three_cols');

   threeColsSection.forEach((section) => new ThreeCols(section as HTMLElement));
};

const handleRelatedPostsSlider = async (): Promise<void> => {
   const slider = document.querySelector<HTMLElement>('.related_posts .related-posts__slider');
   if (!slider) return;

   relatedPostsSlider(slider);
};

const handleContactModal = async (): Promise<void> => {
   const modal = document.querySelector<HTMLElement>('.contact-modal') as HTMLElement;
   const togglers = document.querySelectorAll<HTMLElement>('.contact-modal-toggler');

   if (!togglers) return;

   new Modal(modal, togglers);
};
