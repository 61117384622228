import getHiddenElementHeight from '../utils/getHiddenElementHeight.utlil';
import isBetweenBreakPoints from '../utils/isBetweenBreakPoints';
import { gsap } from 'gsap';

const breakPoints = [
   {
      min: 0,
      max: 800,
   },
   {
      min: 1024,
      max: 1480,
   },
];

export default class Tabs {
   private section: HTMLElement;
   private tabsNavElements: HTMLElement[];
   private activeTabItem: HTMLElement;
   private activeTabContainer: HTMLElement;
   private currentTab: HTMLElement;
   private tabsNav: HTMLElement;
   private tl: GSAPTimeline | null;
   private isOpen: Boolean;
   private breakPoints: string[];
   private isNavigationDropDown: Boolean;

   constructor(section: HTMLElement) {
      this.section = section;
      this.tabsNavElements = [...this.section.querySelectorAll('.tabs-nav__item')] as HTMLElement[];
      this.activeTabItem = this.tabsNavElements.find((item) => item.classList.contains('tabs-nav__item--active')) as HTMLElement;
      this.activeTabContainer = this.section.querySelector('.single-tab--active') as HTMLElement;
      this.currentTab = this.section.querySelector('.tabs__current-tab') as HTMLElement;
      this.tabsNav = this.section.querySelector('.tabs-nav') as HTMLElement;
      this.tl = null;
      this.isOpen = false;
      this.breakPoints = ['(min-width: 800px)', '(min-width: 1480px)', '(min-width: 1024px)', '(min-width: 1280px)'];
      this.isNavigationDropDown = isBetweenBreakPoints(breakPoints);

      this.initListeners();
      this.initTimeLine();
   }

   initListeners = async (): Promise<void> => {
      this.tabsNavElements.forEach((item) => item.addEventListener('click', this.handleTabClick));
      this.currentTab.addEventListener('click', this.handleOpenDropDown);
      this.breakPoints.forEach((item: string) => {
         const media = window.matchMedia(item);
         media.addEventListener('change', this.handleBreakPointChange);
      });
   };

   initTimeLine = async (): Promise<void> => {
      this.tl = gsap.timeline();
      this.tl.to(this.tabsNav, { height: getHiddenElementHeight(this.tabsNav), duration: 0.3 }).pause();
   };

   handleTabClick = async ({ currentTarget }: Event): Promise<void> => {
      if (currentTarget === this.activeTabItem) return;
      this.isNavigationDropDown ? this.handleCloseDropDown(currentTarget) : null;
      const tabToShow = this.section.querySelector(`.${(currentTarget as HTMLElement).dataset.tab}`) as HTMLElement;

      this.toggleSameClassForMultipleElements([this.activeTabContainer, tabToShow], 'single-tab--active');
      this.toggleSameClassForMultipleElements([this.activeTabItem, currentTarget as HTMLElement], 'tabs-nav__item--active');

      this.activeTabItem = currentTarget as HTMLElement;
      this.activeTabContainer = tabToShow as HTMLElement;
   };

   toggleSameClassForMultipleElements = (items: HTMLElement[] | null, className: string) => {
      items?.forEach((item) => item.classList.toggle(className));
   };

   handleOpenDropDown = async (): Promise<void> => {
      if (!this.tl) return;

      this.isOpen = !this.isOpen;
      this.isOpen ? this.tl.play() : this.tl.reverse();
   };

   handleCloseDropDown = async (currentTarget: any): Promise<void> => {
      const currentTabNameContainer = this.section.querySelector<HTMLElement>('.tabs__curent-tab-name');
      if (!currentTabNameContainer) return;

      this.handleOpenDropDown();
      currentTabNameContainer.textContent = currentTarget.textContent.trim();
   };

   handleBreakPointChange = async (): Promise<void> => {
      if (!this.tabsNav) return;

      this.isNavigationDropDown = isBetweenBreakPoints(breakPoints);
      this.tabsNav.removeAttribute('style');
      this.initTimeLine();
   };
}
