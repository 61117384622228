const removePolishCharsFromString = (string: string) => {
   const chars = {
      ą: 'a',
      ć: 'c',
      ę: 'e',
      ł: 'l',
      ń: 'n',
      ó: 'o',
      ś: 's',
      ź: 'z',
      ż: 'z',
   };

   return (
      string
         // split the string into an array
         .split('')
         .map(
            (char) =>
               // check if mapping has a key with the current character
               Object.keys(chars).includes(char)
                  ? chars[char] // if yes, return its replacement
                  : char // if not, return it unchanged
         )
         // join the array to a string
         .join('')
   );
};

export default removePolishCharsFromString;
