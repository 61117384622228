import removePolishCharsFromString from '../../utils/removePolishCharsFromString';
interface searchObject {
   parent: HTMLElement;
   name: String;
   address: String;
}
export default class List {
   private list: HTMLElement;
   private listItems: HTMLElement[];
   private searchTextes: searchObject[];

   constructor(list: HTMLElement) {
      this.list = list as HTMLElement;
      this.listItems = [...this.list.querySelectorAll('li')] as HTMLElement[];
      this.searchTextes = [];

      this.getTextesFromList();
   }

   private getTextesFromList = async (): Promise<void> => {
      this.listItems.forEach((item) => {
         const searchObject: searchObject = {
            parent: item,
            name: removePolishCharsFromString(item.querySelector('.map__list-item-name')?.textContent?.toLowerCase() as string),
            address: removePolishCharsFromString(item.querySelector('.map__list-item-address')?.textContent?.toLowerCase() as string),
         };

         this.searchTextes.push(searchObject);
      });
   };

   public filterList({ target }: Event) {
      const value = removePolishCharsFromString((target as HTMLInputElement).value.toLowerCase());

      if (value === '') return this.renderList(this.listItems);

      const matchingPlaces = this.searchTextes.filter((item) => item.name.includes(value) || item.address.includes(value)).map((item) => item.parent);

      if (matchingPlaces.length < 1) return this.renderList([]);

      this.renderList(matchingPlaces);
   }

   private renderList = async (items: HTMLElement[]): Promise<void> => {
      this.list.innerHTML = '';
      items.forEach((item) => this.list.appendChild(item));
   };
}
