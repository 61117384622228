import Splide from '@splidejs/splide';
import '@splidejs/splide/css';

export const bigFiveSlider = (sliderSection: HTMLElement) => {
   if (!sliderSection) return;

   new Splide(sliderSection, {
      arrows: false,
      perPage: 3,
      gap: '1rem',
      breakpoints: {
         1280: {
            perPage: 2,
         },
         800: {
            perPage: 1,
            gap: 0,
         },
      },
   }).mount();
};

export const boxesSlider = (sliderSection: HTMLElement) => {
   if (!sliderSection) return;

   new Splide(sliderSection, {
      pagination: false,
      perPage: 3,
      breakpoints: {
         800: {
            perPage: 2,
         },
         580: {
            perPage: 1,
         },
      },
   }).mount();
};

export const relatedPostsSlider = (sliderSection: HTMLElement) => {
   if (!sliderSection) return;

   new Splide(sliderSection, {
      arrows: false,
      perPage: 2,
      gap: '1rem',
      breakpoints: {
         1024: {
            perPage: 1,
            gap: 0,
         },
      },
   }).mount();
};

export const threeColsSlider = (sliderSection: HTMLElement) => {
   new Splide(sliderSection, {
      perPage: 2,
      gap: '1rem',
      breakpoints: {
         800: {
            perPage: 1,
            gap: 0,
         },
      },
      arrows: false,
   }).mount();
};
